.bg-image {
  background-image: url(./../../../assets/imgs/bg-image.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.input-placeholder::placeholder {
  color: #000000;
}

.border-hexFF264B {
  border-color: #ff264b;
}

.bg-pink {
  background-color: #ff6c85;
}

.text-xxs {
  font-size: 10px;
}

body {
  font-family: "Montserrat", sans-serif;
}

.border-hexFF264B {
  border-color: #ff264b;
}

.bg-pinkred {
  background-color: #ff264b;
}

.bg-pink {
  background-color: #ff6c85;
}

.text-pinkred {
  color: #ff264b;
}

.border-pinkred {
  border: 2px solid #ff264b;
}

.text-xxs {
  font-size: 10px;
}

.slide-container {
  display: flex;
  transition: transform 0.5s ease;
}
.slide {
  flex: 0 0 auto;
  width: 100%;
  display: none;
}

.select-with-margin::after {
  content: "";
  display: block;
  width: 10px;
  height: 100%;

  pointer-events: none;
  background-color: transparent;
  margin-right: 35px;
}

.overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 27%);
  z-index: 1;
}

.mt-32 {
  margin-top: 0 !important;
}

.mt-48 {
  margin-top: 0 !important;
}

@media (min-width: 200px) and (max-width: 768px) {
  .w-sm-full {
    width: 100% !important;
  }
  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  .logo {
    width: 40% !important;
  }
}

@media (min-width: 980px) and (max-width: 1500px) {
}

.form_wrap {
  position: relative;
}

super {
  color: red;
}

#unique-input:focus + label,
#unique-input:valid + label {
  display: none;
}

.input_wrap {
  position: relative;
}

.input_wrap label {
  color: #000000;
  position: absolute;
  top: 40%;
  left: 4%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
}